import { FC, ReactNode } from 'react';
import { Footer, MobileNavMenu } from './footer';
import { Header } from './header';
import { ParallaxBackground } from 'ui/components/planet';
import Swapper, { QuickSwapButton } from './swapper';

export const MainLayout: FC<{ children: ReactNode }> = ({ children }) => (
  <div
    id="layout"
    className="flex h-dvh flex-col items-center justify-start overflow-y-auto overflow-x-hidden bg-background4 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-white/20">
    <ParallaxBackground />
    <Header />
    {children}
    <Footer />
    <MobileNavMenu />
    <Swapper />
    <QuickSwapButton />
  </div>
);
