import { FaArrowsRotate, FaX, FaXTwitter } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { FC, useRef, useState } from 'react';
import clsx from 'clsx/lite';
import { MultiNavItem } from 'types/routes';
import { FaTelegramPlane, FaMediumM } from 'react-icons/fa';
import { LinkButton } from 'ui/components/button';
import { t } from 'i18next';
import { filter, map } from 'lodash';
import { useAppRoutes } from 'hooks/use-routes';
import { quickSwap } from './swapper';
import { CardModal } from 'ui/components/modal';

export const MobileNavMenu: FC = () => {
  const { routes, currentIndex } = useAppRoutes();
  const ref = useRef<HTMLDivElement>(null);

  const [showPanel, setShowPanel] = useState<string | null>(null);

  return (
    <footer
      className="sticky bottom-0 z-[2] h-fit w-dvw shadow-2xl shadow-background4 lg:hidden"
      style={{ boxShadow: '0px 0px 32px 11px rgb(10 20 35)' }}
      ref={ref}>
      {map(
        filter(routes, (r) => r.multipath),
        (group: MultiNavItem) =>
          showPanel === group.title ? (
            <CardModal
              key={group.title}
              open={showPanel === group.title}
              onClose={() => setShowPanel(null)}
              title={group.title}
              contentClassName="grid grid-cols-2 sm:grid-cols-3 gap-3 p-4"
              subtitle={
                <FaX
                  className="size-8 cursor-pointer self-start p-2 text-white"
                  onClick={() => setShowPanel(null)}
                />
              }>
              {map(group.paths, (route, i) => {
                return (
                  <LinkButton
                    onClick={() => setShowPanel(null)}
                    key={route.title}
                    to={route.pathname}
                    style={{ animationDelay: `${20 * i}ms` }}
                    className={clsx(
                      'flex flex-col gap-2 rounded-lg bg-gr2 p-2 text-white',
                      'no-underline',
                      'flex animate-fade-down flex-col items-center',
                    )}>
                    <img src={route?.img} className="size-24 bg-no-repeat object-cover" />
                    {route.title}
                  </LinkButton>
                );
              })}
            </CardModal>
          ) : null,
      )}
      <nav className="relative z-[2] flex flex-row items-center justify-evenly gap-2 bg-background2 p-2 px-4">
        {routes.map((route, index) =>
          route.multipath ? (
            <div
              key={route.title}
              className={clsx(
                'flex cursor-pointer flex-col items-center justify-center gap-1 text-xs font-bold uppercase no-underline opacity-70',
                currentIndex === index ? 'fill-theme-yellow1 text-accent-btn' : 'fill-white !text-white',
              )}
              onClick={() => setShowPanel(route.title)}>
              {route.icon && <route.icon width="30px" height="30px" className="size-5" />}
              {route.mobileTitle ?? route.title}
            </div>
          ) : (
            <Link
              key={route.title}
              className={clsx(
                'flex cursor-pointer flex-col items-center justify-center gap-1 text-xs font-bold uppercase no-underline opacity-70',
                currentIndex === index ? 'fill-theme-yellow1 text-accent-btn' : 'fill-white !text-white',
              )}
              to={route.pathname}
              onClick={() => setShowPanel(route.title)}>
              {route.icon && <route.icon width="30px" height="30px" className="size-5" />}
              {route.mobileTitle ?? route.title}
            </Link>
          ),
        )}
        <div
          onClick={() => quickSwap.set.open(true)}
          className="-m-2 box-content flex h-full cursor-pointer flex-col items-center justify-center gap-1 rounded-lg bg-gradient-to-l from-gr1 to-gr2 px-4 py-1 text-xs font-bold uppercase text-white no-underline opacity-80">
          <FaArrowsRotate className="size-5 text-white" />
          SWAP
        </div>
      </nav>
    </footer>
  );
};

export const Footer: FC = () => {
  return (
    <>
      <div className="center z-[1] mt-auto flex w-full flex-col items-center justify-center gap-2 bg-background1 p-2 md:flex-row md:gap-10">
        <div className="flex flex-row gap-2">
          <LinkButton className="center !px-2 !py-1" to="https://t.me/TerracVitaCommunity" target="_blank">
            <FaTelegramPlane className="size-4" />
          </LinkButton>
          <LinkButton className="center !px-2 !py-1" to="https://medium.com/@terraport" target="_blank">
            <FaMediumM className="size-4" />
          </LinkButton>
          <LinkButton className="center !px-2 !py-1" to="https://twitter.com/_terraport_" target="_blank">
            <FaXTwitter className="size-4" />
          </LinkButton>
        </div>
        <div className="h-full w-[1px] bg-slate-300" />
        <div className="flex flex-row gap-6 text-sm text-white">
          <Link className="text-white no-underline" to="/privacyPolicy">
            {t('routes.privacy_policy')}
          </Link>
          <Link className="text-white no-underline" to="/privacyPolicy">
            {t('routes.risk_advice')}
          </Link>
          <Link className="text-white no-underline" to="/">
            {t('routes.powered')}
          </Link>
        </div>
      </div>
    </>
  );
};
