export const en = {
  all: {
    home: {
      carousel: {
        first: {
          title: 'Beyond the limits',
          subtitle: 'begin your journey in',
        },
        development_release: {
          title: 'DEVELOPMENT RELEASE!',
          subtitle: 'Ready for the new release?',
        },
        lunc_burn: 'Total LUNC burn',
      },
      main: {
        title: 'EXPLORE THE UNIVERSE',
        subtitle: 'Trade earn farm, and discover all new features of Terraport v3',
      },
      cards: {
        descr_swap: 'Swap crypto instantly',
        descr_liquidity: 'Earn by providing liquidity',
        descr_farm: 'Provide liquidity, get yields',
        descr_stake: 'Stake and earn interests',
      },
      terra_burn: {
        title: 'Total TERRA burned',
        worth: 'Worth <FormattedNumber/>',
        previous: 'Previous burns',
        weeks: '{{value}} Weeks',
      },
      partners: 'Official Partners',
      banners: {
        title: 'Conquering space!',
        subtitle: 'Buy this banner',
        button: 'BUY THIS BANNER',
      },
      community: {
        title: 'Join our crypto space crew!',
        subtitle: 'Take part in the journey',
      },
    },
    launchpad: {
      banner: {
        green:
          'Congratulations! you are <span>eligible</span> because you have more than <span>1500 TERRA</span> on average',
        red: 'You should have at least <span/>\n per day in average to be eligible',
        tooltip_green: 'You must have 1500 TERRA in wallet at 05.00 UTC',
        tooltip_red: 'You have {{value}} TERRA out of 1500 on average',
      },
      description:
        'Defend, conquer and climb the leaderboard. Get $ROCKET and secure a journey to a decentralized world.',
      litepaper: 'Litepaper',
      quests: {
        title: 'Quests',
        points: 'Points earned',
        tooltip_q: 'Quest: {{value}}',
        tooltip_l: 'Leaderboard: {{value}}',
        toast: 'Earned {{value}} points!',
        connect: 'Connect your wallet to see the daily quests!',
      },
      login: {
        title: 'Daily Login +{{value}}',
        description: 'Login to the app daily to earn rewards',
        btn_claim: 'CLAIM!',
        toast: 'Earned {{value}} points!',
        btn_claimed: 'CLAIMED',
      },
      share: {
        title: 'Share on Twitter +{{value}}',
        description: 'Share on Twitter to earn rewards',
        btn_claim: 'SHARE',
        btn_claimed: 'CLAIMED',
      },
      swap: {
        title: 'Swap +{{value}}',
        description: 'Swap 50$ worth of tokens to earn rewards',
        btn_claim: 'CLAIM',
        toast: 'Earned {{value}} points!',
        btn_claimed: 'COMPLETED',
      },
      referral: {
        title: 'Referral +{{value}}',
        description: 'Refer a friend to earn rewards',
        btn: 'Referral Link',
        toast: 'Referral Link copied to clipboard',
        tooltip: 'For every friend that complete the swap quest you will earn 150 points',
      },
      steps: {
        swap: {
          title: '1.SWAP',
          subtitle: "LET'S TRADE",
          description: 'Earn up to 5 Points for\n every $1 SWAP\n volume.',
        },
        rise: {
          title: '2.RISE',
          subtitle: 'CLIMBING\nTHE LEADERBOARD',
          description: 'keep your $LUNC \n delegated to the \n<link/>',
          validator: 'Terraport Validator',
        },
        time: {
          title: '3.TIME',
          subtitle: 'PAY ATTENTION\nTO THE DEADLINE',
          days: 'DAYS',
        },
        claim: {
          title: '4.CLAIM',
          subtitle: 'CLAIM YOUR\nAIRDROP!',
          description: 'Be sure to be\nELIGIBLE! ',
        },
      },
    },
    trade: {},
    swap: {},
    staking: {},
    farming: {},
    vesting: {},
    development: {
      tokens: {},
      pairs: {},
      farms: {},
    },
    governance: {},
    routes: {
      privacy_policy: 'Privacy Policy',
      risk_advice: 'Risk Advice',
      docs: 'Docs',
      powered: 'Powered by TerraCVita',
    },
    common: {
      history: 'Launchpad History',
      rocket: 'Rocket',
      token: 'Token',
      connect: 'Connect Wallet',
      trade: 'Trade',
      swap: 'Swap',
      liquidity: 'Liquidity',
      farm: 'Farm',
      farming: 'Farming',
      staking: 'Staking',
      stake: 'Stake',
      earn: 'Earn',
      provide: 'Provide',
      withdraw: 'Withdraw',
      unstake: 'Unstake',
      leaderboard: 'Leaderboard',
      governance: 'Governance',
      other: 'Other',
      development: 'Development',
      launchpad: 'Launchpad',
      vesting: 'Vesting',
      buy_crypto: 'Buy Crypto',
      buy_tokens: 'Buy Tokens',
      wallet: 'Wallet',
      claim: 'Claim',
    },
  },
};
