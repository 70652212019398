import axios from 'axios';
import { classicNetwork } from '../consts/networks';
import { getSymbol } from '../helper/utils';
import { walletState } from '../state/wallet-state';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

export const loadTaxInfo = async (contract_addr: string) => {
  if (!contract_addr || contract_addr.startsWith('ibc')) {
    return '';
  }

  let taxCap = '';
  try {
    const res = (
      await axios.get(
        `${classicNetwork.fcd}/terra/treasury/v1beta1/tax_caps/${contract_addr}`,
      )
    ).data;
    taxCap = res.tax_cap;
  } catch (error) {
    console.error(error);
  }
  return taxCap;
};

export const loadTaxRate = async () => {
  let taxRate = '0';
  try {
    const url = `${classicNetwork.fcd}/terra/treasury/v1beta1/tax_rate`;
    const res = (await axios.get(url)).data;
    taxRate = res.tax_rate;
  } catch (error) {
    console.error(error);
  }
  return taxRate;
};

export const useTaxRate = async () => {
  const { data } = useQuery<string>({
    queryKey: ['tax-rate'],
    queryFn: async () => {
      const { data } = await axios.get(
        `${classicNetwork.fcd}/terra/treasury/v1beta1/tax_rate`,
      );
      return data.tax_rate;
    },
    refetchInterval: 3600000,
  });
  useEffect(() => {
    if (data) walletState.set.taxRate(data);
  }, [data]);
  return data;
};

export const useGasPrice = async () => {
  const { data } = useQuery<{ [denom: string]: string }>({
    queryKey: ['gas-prices'],
    queryFn: async () => {
      const { data } = await axios.get(
        `${classicNetwork.fcd}/v1/txs/gas_prices`,
      );
      return data;
    },
    refetchInterval: 3600000,
  });
  // useEffect(() => {
  //   if (data)
  //     // walletState.set.gasPrices(data);
  // }, [data]);
  return data;
};
export const loadGasPrice = async (symbol: string) => {
  try {
    const symbolName = getSymbol(symbol) || symbol;
    const url = `${classicNetwork.fcd}/v1/txs/gas_prices`;
    const res = (await axios.get(url)).data;

    let gasPrice = '0';
    if (['uusd', 'uluna'].includes(symbolName)) {
      gasPrice = res?.[symbolName];
    }

    return gasPrice;
  } catch (err) {
    console.log(err);
  }
};
