import { FC, ReactNode, useEffect, useMemo } from 'react';
import { WalletModal } from '../../ui/modals/wallet-modal';
import { useGasPrice, useTaxRate } from '../../api/api';
import { useGetMeQuery } from 'api/terraport/base';
import { useSearchParams } from 'react-router-dom';
import { postReferral } from 'api/terraport/quests';
import { useProducts } from 'state/paid-ops';

export const WalletConnectProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  useTaxRate();
  useGasPrice();
  useProducts();
  const { data } = useGetMeQuery();
  const [params, setParam] = useSearchParams();
  const referrer = useMemo(() => params.get('referrer'), [params]);
  // const [referrer, setReferrer] = useLocalStorage('referrer', null);

  useEffect(() => {
    if (data?.address && referrer && data?.address !== referrer) {
      postReferral(data?.address, referrer);
      params.delete('referrer');
      setParam(params);
    }
  }, [data, referrer]);
  return (
    <>
      <WalletModal />
      {children}
    </>
  );
};
